import React, { Fragment, useEffect, useContext } from "react"
import { FirebaseContext, useAuth } from "../../firebase"
import * as R from "ramda"
import { graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"
import LiftContent from "../../components/liftContent"
import LayoutController from "../../components/layoutController"


const ExpoVirtual = ({ data }) => {
  // content
  const content = R.path(["content"], data)
  const language =  R.path(["node_locale"], content)
  const slug = R.path(["slug"], content)
  const title = R.path(["virtualTitle"], content)
  const text = R.path(["virtualContentText"], content)
  const mainImage = R.path(["mainImage", "gatsbyImageData"], content)
  const video = R.path(["video", "file", "url"], content)
  const videoPosterImage = R.path(["videoPosterImage", "file", "url"], content)
  const contentText = R.path(["contentText", "contentText"], content)
  const mainImageSEO = R.path(["mainImage", "file", "url"], content)
  const liftContent = R.path(["virtualLiftContent"], content)

  const { user, firebase, loading } = useAuth()
  //const { firebase } = useContext(FirebaseContext)

  const returnUrl = `/${slug}/virtuaalimessut`

  useEffect(() => {
    // Runs ONCE after initial rendering
    // and after every rendering ONLY IF 'user' changes (from null to not null)
    // loading = false, user = null, lead user to login page.
    if (!loading && !user) {
      navigate("/login", { state: { returnUrl: returnUrl } })
    }
  }, [loading, user, returnUrl])


  // Hide code check from Naantali expo

  // useEffect(() => {
  //   async function checkRegisteredCode(user, returnUrl) {
  //     const response = await firebase.checkRegisteredCode({
  //       email: user.email
  //     })
  //     if (response.data._size == 0) {
  //       navigate("/code", { state: { returnUrl: returnUrl } })
  //     }
  //   }
  //   if (firebase && user && returnUrl == '/naantali-2022/virtuaalimessut') {
  //     checkRegisteredCode(user, returnUrl)
  //   }
  // }, [firebase, user, returnUrl])

  return (
    <LayoutController language={language}> 
      <Seo
        seoTitle={title}
        seoDesc={contentText}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <section className="mb-16">
        <header className="bg-blue pt-10 pb-[150px] md:pb-[300px] px-5">
          <div className="max-w-3xl mx-auto text-center">
            {title && <h1>{title}</h1>}
            {text && <p>{text}</p>}
          </div>
        </header>
        <div className="max-w-6xl m-auto relative mt-[-150px] md:mt-[-300px] px-5">
          {video ? (
            <video c width="100%" poster={videoPosterImage} controls>
              <source src={video} type="video/mp4" />
            </video>
          ) : (
            <GatsbyImage
              className="w-full aspect-[2/1]"
              image={mainImage}
              alt=""
            />
          )}
        </div>
        {liftContent && (
          <div className="mx-12">
            <LiftContent pageContent={content} />
          </div>
        )}
      </section>
    </LayoutController>
  )
}

export default ExpoVirtual

// eslint-disable-next-line no-undef
export const expoVirtualQuery = graphql`
  query expoVirtual($parentSlug: String) {
    content: contentfulMessu(slug: { eq: $parentSlug }) {
      id
      slug
      node_locale
      title
      mainImage {
        file {
          url
        }
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
      }
      video {
        file {
          url
          fileName
          contentType
        }
      }
      videoPosterImage {
        file {
          url
        }
      }
      virtualTitle
      virtualContentText
      virtualLiftContent {
        ... on Node {
          ... on ContentfulNostolista {
            __typename
            id
            color
            buttonText
            buttonAction {
              ... on Node {
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  slug
                }
              }
            }
            title
          }
          ... on ContentfulVarinosto {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            ctaButton1Text
            ctaButton1Action {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
                ... on ContentfulKategoriasivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on ContentfulSisaltoelementti {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            image {
              id
              file {
                url
              }
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
            ctaButtonText
            ctaButtonAction
          }
          ... on ContentfulVideonosto {
            __typename
            id
            title
            contentText {
              contentText
            }
            videoUrl
            thumbNail {
              id
              file {
                url
              }
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
            ctaButtonText
            buttonSlug
          }
          ... on ContentfulTekstilinkkilista {
            __typename
            id
            title
            contentText {
              contentText
            }
            liftList {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  id
                  title
                  slug
                }
                ... on ContentfulKategoriasivu {
                  id
                  slug
                  title
                }
                ... on ContentfulKohdesivu {
                  id
                  name
                  slug
                }
              }
            }
          }
          ... on ContentfulTekstikentta {
            __typename
            title
            text {
              text
            }
            ctaButtonText
            ctaButtonSlug
            wide
          }
          ... on ContentfulKohdekartta {
            __typename
            id
            title
            premiseYear
            map {
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
          }
          ... on ContentfulAnimoidutNostot {
            __typename
            id
            title
            lifts {
              id
              title
              text {
                text
              }
              buttonText
              buttonLink
              buttonAction {
                ... on Node {
                  ... on ContentfulKategoriasivu {
                    __typename
                    id
                    slug
                  }
                  ... on ContentfulArtikkelisivu {
                    __typename
                    id
                    slug
                  }
                  ... on ContentfulKohdesivu {
                    __typename
                    id
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
